import './App.css';
import * as ReactGA from "react-ga";
import {useEffect} from "react";
ReactGA.initialize('UA-113368609-3');

function App() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

  return (
    <div className="App">
      <header className="App-header">
          <p>
              I mean, to be honest with you...
          </p>
          <p>
              Might not be the best idea just now
          </p>
      </header>
    </div>
  );
}

export default App;
